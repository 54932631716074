<template>
  <div class="mt-5 px-xl-5 mb-4" v-if="data != null">
    <h6 class="page-title pb-2 text-uppercase">
      <b-button class="main-btn rounded me-2" @click="goToLawFirms">
        <b-icon icon="arrow-left"></b-icon></b-button>
      {{ $t("criteria.request-pending") }}
    </h6>
    <b-tabs fill nav-class="dash-nav-tab">
      <b-tab title="Información" active title-item-class="dash-tab">
        <b-row class="mt-4">
          <b-col md="6">
            <b-row class="mb-2">
              <b-col sm="4">
                <label class="details-title">{{ $t("general.name") }}</label>
              </b-col>
              <b-col>
                <span class="details-title-value">{{ data.lawFirm.name.toUpperCase() }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="4">
                <label class="details-title">{{
    $t("general.creation-date")
  }}</label>
              </b-col>
              <b-col>
                <span class="details-title-value">{{
      formatDate(data.lawFirm.creationDate)
    }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="4">
                <label class="details-title">CUR</label>
              </b-col>
              <b-col>
                <span class="details-title-value">{{ data.lawFirm.cur }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="4">
                <label class="details-title">{{
    $t("general.cur-status")
  }}</label>
              </b-col>
              <b-col>
                <span class="details-title-value">{{
      $t(`cur-status.${data.lawFirm.curStatus}`)
    }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="4">
                <label class="details-title">Tipo de solicitud</label>
              </b-col>
              <b-col>
                <span class="details-title-value" :class="`criteria-type-${data.type}`">{{
    $t(`criteria-type.${data.type}`) }}</span>
              </b-col>
            </b-row>
            <b-row class="mt-4" v-if="data.status === 0">
              <b-col>
                <b-button class="main-btn" @click="process" :disabled="!isProcess">
                  Procesar
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-else>
              <b-col sm="4">
                <label class="details-title">Estado</label>
              </b-col>
              <b-col>
                <span class="details-title-value">{{
    $t(`review-request-status.${data.status}`)
  }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <label class="details-title">Observaciones</label><br />
            <span class="details-title-value">{{ data.observation }}</span><br />
            <div class="mt-3" v-if="data.type === 0 && data.criteriaDocuments.length > 0">
              <p>Documentos de inactivación</p>
              <b-button size="sm" variant="secondary" class="me-2" v-for="(document, di) in data.criteriaDocuments"
                :key="di" @click="getDocument(document.id, document.document)">{{ document.document }}</b-button>
            </div>
            <div class="mt-3">
              <p v-if="data.type === 0 && data.criteriaDocuments.length > 0">
                Documentos de justificación
              </p>
              <b-button size="sm" variant="secondary" class="me-2" v-for="(document, d) in data.documents" :key="d"
                @click="getDocument(document.id, document.document)">{{ document.document }}</b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <p>Criterios</p>

          <table class="table" v-if="data.criterias.length">
            <thead>
              <tr>
                <th class="text-center">Número de la solicitud</th>
                <th>Criterio</th>
                <th class="text-center">Aprobado</th>
                <th class="text-center">Rechazado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(criteria, c) in data.criterias" :key="c">
                <td align="center">{{ data.code }}</td>
                <td>{{ criteria.criteriaName }}</td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 1" />
                  <input v-else-if="data.status === 0" type="radio" v-model="criteria.status" :value="1"
                    @change="isProcess = true" />
                </td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 2" />
                  <input v-else-if="data.status === 0" type="radio" v-model="criteria.status" :value="2"
                    @change="isProcess = true" />
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table" v-if="data.justificationCriterias.length">
            <thead>
              <tr>
                <th class="text-center">Número de la solicitud</th>
                <th>Criterio de inactivación</th>
                <th>Justificación de activación</th>
                <th class="text-center">Aprobado</th>
                <th class="text-center">Rechazado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(criteria, h) in data.justificationCriterias" :key="h">
                <td align="center">{{ data.code }}</td>
                <td>{{ criteria.criteriaName }}</td>
                <td>{{ criteria.justificationCriteriaName }}</td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 1" />
                  <input v-else-if="data.status === 0" type="radio" v-model="criteria.status" :value="1"
                    @change="isProcess = true" />
                </td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 2" />
                  <input v-else-if="data.status === 0" type="radio" v-model="criteria.status" :value="2"
                    @change="isProcess = true" />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </b-tab>
      <b-tab title="Historial" title-item-class="dash-tab">
        <ReviewRequestTimeline :data="data" />
      </b-tab>
    </b-tabs>

    <!-- Modal -->
    <b-modal v-model="showModal" content-class="rounded-0" dialog-class="modal-lg" body-bg-variant="light" centered
      no-close-on-backdrop hide-footer hide-header>
      <div class="container">
        <b-row class="text-center p-3">
          <b-col>
            <p class="details-title">
              <strong>Esta a punto de
                <u>{{ $t(`criteria-type-label.${data.type}`) }}</u> a un Agente
                Residente</strong>
            </p>
            <p class="details-title">
              Primero revisé todos los criterios e Información del agente
              residente. Si está de acuerdo, presione clic en aceptar.
            </p>
          </b-col>
        </b-row>

        <b-row class="text-center">
          <b-col>
            <label class="details-title">{{ $t("general.name") }}</label><br />
            <span class="details-title-value">{{ data.lawFirm.name }}</span>
          </b-col>
          <b-col>
            <label class="details-title">CUR</label><br />
            <span class="details-title-value">{{ data.lawFirm.cur }}</span>
          </b-col>
          <b-col>
            <label class="details-title">Tipo de acción</label><br />
            <span class="details-title-value" :class="`criteria-type-${data.type}`">{{ $t(`criteria-type.${data.type}`)
              }}</span>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <p class="details-title">Resumen Motivos</p>

          <table class="table" v-if="data.criterias.length">
            <thead>
              <tr>
                <th>Criterio</th>
                <th class="text-center">Aprobado</th>
                <th class="text-center">Rechazado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(criteria, i) in data.criterias" :key="i">
                <td>{{ criteria.criteriaName }}</td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 1" />
                </td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 2" />
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table" v-if="data.justificationCriterias.length">
            <thead>
              <tr>
                <th>Criterio de inactivación</th>
                <th>Criterio de justificación</th>
                <th class="text-center">Aprobado</th>
                <th class="text-center">Rechazado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(criteria, k) in data.justificationCriterias" :key="k">
                <td>{{ criteria.criteriaName }}</td>
                <td>{{ criteria.justificationCriteriaName }}</td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 1" />
                </td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 2" />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>

        <b-row class="text-center mt-2">
          <b-col>
            <b-overlay :show="isAccept" rounded opacity="0.7" spinner-small spinner-variant="primary"
              class="d-inline-block">
              <b-button class="main-btn me-3" size="sm" @click="accept" :disabled="isAccept">Aceptar</b-button>
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="showModal = false">Cancelar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import ReviewRequestTimeline from "./ReviewRequestTimeline.vue";
import { mapActions } from "vuex";

export default {
  name: "review-request",
  components: {
    ReviewRequestTimeline,
  },
  async created() {
    await this.retrieveData();
  },
  data() {
    return {
      data: null,
      showModal: false,
      isProcess: false,
      isAccept: false,
    };
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    async retrieveData() {
      this.isBusy = true;
      try {
        let response =
          await RepositoryFactory.lawFirm.getRequestActivationInactivation({
            id: this.$route.params.id,
          });
        this.data = response.data;
      } catch (error) {
        window.console.log(error);
      }
      this.isBusy = false;
    },
    formatDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
    goToLawFirms() {
      this.$router.push({
        name: "law-firms",
        params: {
          tab: 1,
          reviewTab: this.$route.params.reviewTab,
        },
      });
    },
    process() {
      this.showModal = true;
    },
    async accept() {
      if (this.data.status === 0) {
        var payload = [];

        for (let i = 0; i < this.data.criterias.length; i++) {
          payload.push({
            id: this.data.criterias[i].id,
            status: this.data.criterias[i].status,
          });
        }

        for (let i = 0; i < this.data.justificationCriterias.length; i++) {
          payload.push({
            id: this.data.justificationCriterias[i].id,
            status: this.data.justificationCriterias[i].status,
          });
        }

        try {
          this.isAccept = true;
          let response =
            await RepositoryFactory.lawFirm.processRequestActivationInactivation(
              {
                id: this.$route.params.id,
                type: this.data.type,
                processCriterias: payload,
              }
            );

          this.openModal();
          if (response.data) {
            this.setModalInfo({
              title: "Aviso",
              message: "Confirmación enviada.",
              routeName: "law-firms",
            });
          } else {
            this.setModalInfo({
              title: "Error",
              message: "Error al confirmar.",
            });
          }
        } catch (error) {
          window.console.log(error);
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: "Error al consumir servicio.",
          });
        } finally {
          this.isAccept = false;
          this.showModal = false;
        }
      }
    },
    async getDocument(id, doc) {
      this.generating = true;
      try {
        let response = await RepositoryFactory.lawFirm.getDocumentById({
          id: id,
          document: doc,
        });

        if (response.data) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", doc);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      } catch (error) {
        window.console.log(error);
      } finally {
        this.generating = false;
      }
    },
  },
};
</script>

<style scoped>
.criteria-type-0 {
  text-transform: uppercase;
  color: green;
}

.criteria-type-1 {
  text-transform: uppercase;
  color: red;
}

.table {
  color: #406892;
}

.table td,
.table th {
  border: 1px solid #406892;
}
</style>
